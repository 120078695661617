.container {
  padding: 1rem 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .logoWrapper {
    text-align: center;
    .logo {
      height: 16rem;
    }
    p {
      font-size: 2rem;
      margin: 0;
      font-weight: 600;
      display: none;
    }
    h1 {
      display: none;
    }
    span {
      font-size: 1.5rem;
    }
  }
  .about {
    text-align: center;
    span {
      color: #666;
    }
    .divider {
      margin: 2rem auto;
      width: 3.5rem;
      height: 0.188rem;
      background-color: #666;
      border-radius: 0.25rem;
    }
  }
}
