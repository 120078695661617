.container {
  text-align: center;
  color: #111;
  margin-bottom: 5rem;
  span {
    color: #666;
  }
  ul {
    margin: 0 auto;
    max-width: 75rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 3rem;
    list-style-type: none;
    padding-left: 0;
    padding-right: 1rem;
    font-weight: 600;
    text-align: left;
    position: relative;
  }
  li {
    padding-left: 5rem;
    margin-bottom: 3rem;
    position: relative;
    font-size: 1.5rem;
  }
  .oddLastLi {
    left: 50%;
  }
  li::before {
    content: '';
    width: 2rem;
    height: 2rem;
    background-image: url('../../../../../assets/images/icons/listIcon.svg');
    background-size: cover;
    display: inline-block;
    position: absolute;
    left: 1.5rem;
    margin-right: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    ul {
      grid-template-columns: 1fr;
    }
    .oddLastLi {
      left: unset;
    }
  }
}
