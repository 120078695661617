.container {
  padding: 4rem 1rem;
  .wrapper {
    margin: 0 auto;
    max-width: 50rem;
  }
  .title {
    text-align: center;
    span {
      color: #666;
      font-size: 1.25rem;
    }
    margin-bottom: 4rem;
  }
  .tabs {
    .tab {
      box-shadow: none;
      .accordionTitle {
        font-size: 1.25rem;
        font-weight: 600;
      }
      .divider {
        width: 100%;
        height: 0.05rem;
        background-color: #c6c6c6;
      }
      .context {
        p {
          font-size: 1.25rem;
          font-weight: 500;
          font-style: italic;
          margin: 0;
        }
        .description {
          white-space: pre-wrap;
          font-size: 1.25rem;
          font-weight: 500;
        }
        ul,
        ol {
          padding-left: 1rem;
          li {
            margin: 1rem 0;
            text-align: left;
            font-size: 1rem;
            white-space: pre-wrap;
          }
        }
        a {
          margin: 1rem 0;
          display: flex;
          align-items: center;
          svg {
            width: 2rem;
            height: 2rem;
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
