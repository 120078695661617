.container {
  a {
    text-decoration: none;
    color: inherit;
  }
  .wrapper {
    max-width: 50rem;
    margin: 0 auto;
    font-size: 1.5rem;
  }
  .info {
    padding: 2rem 1rem;
    background-color: #fff;
    text-align: center;
    .cred {
      margin-top: 2rem;
      p {
        margin: 0.5rem 0;
      }
    }
  }
  .links {
    padding: 3rem 1rem;
    background-color: #111;
    text-align: center;
    svg {
      margin: 0 0.25rem;
      height: 3rem;
      width: 3rem;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;

      margin: 2rem auto;
      background-color: transparent;
      border: none;
      color: #fff;
      font-size: 1rem;
      svg {
        margin-left: 1rem;
        height: 1.5rem;
        transform: rotate(180deg);
      }
    }
  }
}
