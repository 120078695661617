.container {
  padding: 5rem 1.5rem;
  text-align: center;
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.6)),
    url('../../../../../assets//images/backgrounds/militaryBg.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .wrapper {
    max-width: 50rem;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    color: #fff !important;
  }
  span {
    font-size: 1.25rem;
  }
  .arrowDown {
    margin-top: 2.5rem;
    height: 3rem;
  }
  .getBtn {
    width: 100%;
    height: 4rem;
    display: inline-block;
    margin: 1rem 0.188rem;
    padding: 0.5rem 1.85rem;
    color: #fff;
    background-color: #273cd9;
    text-transform: uppercase;
    border-radius: 0.3rem;
    border: none;
    font-size: 1rem;
    font-weight: 600;
  }
}
