.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.menuContainer {
  background-color: #3c4346;
  height: 4rem;
  padding: 1.25rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.burgerMenu {
  width: 1.875rem;
  height: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  .burgerLine {
    width: 100%;
    height: 0.25rem;
    background-color: #fff;
    transition: transform 0.3s ease-in-out;
  }
  .burgerLine:nth-child(2) {
    width: 80%;
    align-self: self-end;
  }

  &.open {
    .burgerLine:first-child {
      transform: rotate(-45deg) translate(-0.313rem, 0.313rem);
    }

    .burgerLine:nth-child(2) {
      opacity: 0;
    }

    .burgerLine:last-child {
      transform: rotate(45deg) translate(-0.375rem, -0.375rem);
    }
  }
}

.dropDownLayer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding-bottom: 2rem;
  box-shadow: 0 0 1rem 0 #000;
}

.mainIcon {
  height: 15rem;
}

.title {
  font-weight: 700;
  font-size: 2rem;
  margin: 1rem 0;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: 600;
}

.phoneContainer {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  svg {
    height: 2rem;
    width: 2rem;
    margin-right: 0.75rem;
    transform: translateY(0.2rem);
    path {
      fill: #111;
    }
  }
  .phoneNumber {
    a {
      text-decoration: none;
      border-bottom: rgb(39, 60, 217) solid;
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
}
.messengers {
  margin-top: 1rem;
  font-weight: 600;
  font-size: 1rem;
}

.optionEnter {
  opacity: 0;
}

.optionEnterActive {
  opacity: 1;
  transition: opacity 500ms, transform 500ms;
}

.optionExit {
  opacity: 1;
}

.optionExitActive {
  opacity: 0;
  transition: opacity 500ms, transform 500ms;
}
