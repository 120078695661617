.animatedIconsContainer {
  position: fixed;
  cursor: pointer;
  bottom: 3.125rem;
  right: 1.875rem;
  z-index: 1001;

  .iconComponent {
    position: relative;
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 50%;
    border: 0.25rem solid #fff;
    background-color: #2196f3;
    box-shadow: #111;
    animation: pulse 2s infinite;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 700;
    font-size: 1.5rem;
    touch-action: none;
    transition: background-color 0.5s ease-in-out;
    box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.3);
    z-index: 1001;

    &::after {
      content: '';
      position: absolute;
      top: 0.188rem;
      left: -0.125rem;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-image: url('../../../../../assets/images/icons/phoneIcon.svg');
    }
    &.active {
      background-color: #fff;
      animation: none;
      border: none;
      &::after {
        top: 0;
        left: 0;
        background-size: 1rem;
        background-image: url('../../../../../assets/images/icons/close.svg');
        background-repeat: no-repeat;
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.smallIconsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  position: absolute;
  top: -12.5rem;
  right: -0.313rem;
  padding: 0.625rem;
  z-index: 1000;

  svg {
    width: 3.125rem;
    height: 3.125rem;
  }
}

.optionEnter {
  opacity: 0;
  transform: translateY(5rem);
}

.optionEnterActive {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}

.optionExit {
  opacity: 1;
  transform: translateY(0);
}

.optionExitActive {
  opacity: 0;
  transform: translateY(5rem);
  transition: opacity 500ms, transform 500ms;
}
