@keyframes shimmer {
  100% {
    left: 100%;
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  button {
    width: 100%;
    height: 3rem;
    display: inline-block;
    margin: 1rem 0;
    padding: 0.5rem 1.85rem;
    color: #fff;
    background-color: #273cd9;
    text-transform: uppercase;
    border-radius: 0.3rem;
    border: none;
    font-size: 1rem;
    font-weight: 600;
    position: relative;
    overflow: hidden;
  }
  button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 30%;
    height: 100%;
    background: linear-gradient(to right, transparent 0%, #f1f2f536 50%, transparent 100%);
    animation: shimmer 2s infinite;
  }
  .nameFieldWrapper {
    height: 3rem;
    margin: 1rem 0;
    padding: 0 2rem;
    border: 0.063rem solid #fff;
    border-radius: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .messageWrapper {
    height: 9rem;
    margin: 1rem 0;
    padding: 1rem 2rem;
    box-sizing: border-box;
    border: 0.063rem solid #fff !important;
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    background-color: transparent;
  }
  .phoneFieldWrapper {
    height: 3rem;
    margin: 1rem 0;
    padding: 0 2rem;
    border: 0.063rem solid #fff;
    border-radius: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .flag {
      height: 1.5rem;
      margin-right: 1rem;
    }
    span {
      margin-right: 0.5rem;
      color: #fff;
    }
  }
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: none;
    font-size: 1rem;
    color: #fff;
    &:focus,
    &:active,
    &:hover {
      outline: none;
      border: none;
      color: #fff;
      background-color: transparent;
    }
  }
  .errors {
    padding: 1rem;
    border-radius: 0.3rem;
    background-color: #ff1500;
    div {
      margin: 0.5rem 0;
    }
  }
}
