.container {
  padding: 5rem 1rem;
  text-align: center;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url('../../../../../assets/images/backgrounds/militaryBg.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .wrapper {
    max-width: 40rem;
    margin: 0 auto;
    color: #fff !important;
  }
  .formWrapper {
    min-height: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
