.container {
  svg {
    width: 13rem !important;
    height: 13rem !important;
    fill: green;
  }
  p {
    font-size: 1.5rem;
  }
}
