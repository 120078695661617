.container {
  margin-top: 4rem;
  padding-top: 18rem;
  text-align: center;
  background: url('../../../../../assets/images/backgrounds/bg4.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;

  .wrapper {
    max-width: 50rem;
    margin: 0 auto;
  }

  .btnWrapper {
    margin-top: 5rem;
    .arrowWrapper {
      height: 4.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .arrowDown {
        width: 2rem;
        animation: floatUpDown 1.5s infinite alternate;
      }
    }
  }

  .freeConversation {
    width: 95%;
    height: 4rem;
    display: inline-block;
    margin: 0.438rem 0.188rem;
    padding: 0.5rem 1.85rem;
    color: #fff;
    background-color: #273cd9;
    text-transform: uppercase;
    border-radius: 0.188rem;
    border: none;
    font-size: 1rem;
    font-weight: 600;
  }

  .phoneButton {
    color: #111;
    background-color: #fff;
  }
}

@keyframes floatUpDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(1rem);
  }
}

@media only screen and (min-width: 78rem) {
  .container {
    background-position: top;
  }
}
